<template>
  <div>
    <div :class="'__divTopMain'">
      <div :class="['__divBack']">
        <v-container>
          <v-row>
            <v-col :class="['__divColTopLeft']">
              <img src="../../../public/assets/img/logopiccargo.svg" alt="" />
            </v-col>
            <v-col :class="['__divColTopRight']">
              <v-btn
                class="mx-2"
                fab
                small
                color="white"
                @click="sheet = !sheet"
              >
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col :class="['__divColLeft']" lg="6" cols="12">
              <h1>Cotizar tu Flete y Aduana</h1>
              <p>...nunca fue tan facil</p>
            </v-col>
            <v-col lg="6" cols="12" :class="['__divColRight']">
              <img src="../../../public/assets/img/calculadora.png" alt="" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="red" @click="sheet = !sheet">
          close
        </v-btn>
        <div class="py-3">
          This is a bottom sheet using the controlled by v-model instead of
          activator
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
export default {
  name: "TopMedia",
  data: () => ({
    sheet: false,
    tiles: [
      { img: "keep.png", title: "Keep" },
      { img: "inbox.png", title: "Inbox" },
      { img: "hangouts.png", title: "Hangouts" },
      { img: "messenger.png", title: "Messenger" },
      { img: "google.png", title: "Google+" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.__divTopMain {
  background: url("../../../public/assets/img/backdesktop.png");
  background-size: 100%;
  height: 50vh;
  width: 100vw;

  color: white;

  @media (max-width: 800px) {
    background: url("../../../public/assets/img/backmobil.png");
  }

  .__divBack {
    background: #00000070;
    height: 100%;
    width: 100%;

    .__divColTopLeft {
      padding: 0.5vw 10vw;

      img {
        width: 300px;
      }

      @media (max-width: 800px) {
        padding: 10px;
        text-align: center;

        img {
          width: 200px;
        }
      }
    }

    .__divColTopRight {
      text-align: right;
      padding: 0.5vw 10vw;

      @media (max-width: 800px) {
        padding: 10px;
      }
    }

    .__divColLeft {
      padding: 0vw 1vw 0vw 10vw;

      h1,
      p {
        font-size: 3.5vw;
      }

      @media (max-width: 800px) {
        padding: 0px;
        h1,
        p {
          text-align: center;
          font-size: 5.5vw;
        }
      }
    }

    .__divColRight {
      text-align: center;

      img {
        max-width: 22vw;
      }

      @media (max-width: 800px) {
        img {
          max-width: 60vw;
        }
      }
    }
  }
}
</style>
