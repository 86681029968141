var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{class:['__divMainTipo'],attrs:{"xs":"12"}},[_c('div',{class:[
            '__divContenedor',
            { __contenedorActivo: _vm.lcl1 > 0 },
            { __error: _vm.errorAct } ]},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',[_vm._v("Contenedor")]),_c('p',[_vm._v("20' STANDARD")]),_c('p',{class:['__infoContenedor'],on:{"click":function($event){return _vm.activeInfo(1)}}},[_vm._v(" Ver detalles ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('p',[_vm._v("Cantidad")]),_c('v-text-field',{ref:"lcl1",attrs:{"type":"number","min":"0","solo":"","dense":""},on:{"change":_vm.confir1},model:{value:(_vm.lcl1),callback:function ($$v) {_vm.lcl1=$$v},expression:"lcl1"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:['__divImg', { __contenedorActivo: _vm.lcl1 > 0 }]},[(_vm.lcl1 <= 0)?_c('img',{attrs:{"src":require("../../public/assets/img/container-inactive.svg"),"alt":""},on:{"click":_vm.alcl1}}):_vm._e(),(_vm.lcl1 > 0)?_c('img',{class:['__imgCheck'],attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])])],1)],1),_c('div',{class:[
            '__divContenedor',
            { __contenedorActivo: _vm.lcl2 > 0 },
            { __error: _vm.errorAct } ]},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',[_vm._v("Contenedor")]),_c('p',[_vm._v("40' STANDARD")]),_c('p',{class:['__infoContenedor'],on:{"click":function($event){return _vm.activeInfo(2)}}},[_vm._v(" Ver detalles ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('p',[_vm._v("Cantidad")]),_c('v-text-field',{ref:"lcl2",attrs:{"type":"number","solo":"","min":"0","dense":""},on:{"change":_vm.confir2},model:{value:(_vm.lcl2),callback:function ($$v) {_vm.lcl2=$$v},expression:"lcl2"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:['__divImg', { __contenedorActivo: _vm.lcl2 > 0 }]},[(_vm.lcl2 <= 0)?_c('img',{attrs:{"src":require("../../public/assets/img/container-inactive.svg"),"alt":""},on:{"click":_vm.alcl2}}):_vm._e(),(_vm.lcl2 > 0)?_c('img',{class:['__imgCheck'],attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])])],1)],1),_c('div',{class:[
            '__divContenedor',
            { __contenedorActivo: _vm.lcl3 > 0 },
            { __error: _vm.errorAct } ]},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',[_vm._v("Contenedor")]),_c('p',[_vm._v("40' HC")]),_c('p',{class:['__infoContenedor'],on:{"click":function($event){return _vm.activeInfo(3)}}},[_vm._v(" Ver detalles ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('p',[_vm._v("Cantidad")]),_c('v-text-field',{ref:"lcl3",attrs:{"type":"number","solo":"","min":"0","dense":""},on:{"change":_vm.confir3},model:{value:(_vm.lcl3),callback:function ($$v) {_vm.lcl3=$$v},expression:"lcl3"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:['__divImg', { __contenedorActivo: _vm.lcl3 > 0 }]},[(_vm.lcl3 <= 0)?_c('img',{attrs:{"src":require("../../public/assets/img/container-inactive.svg"),"alt":""},on:{"click":_vm.alcl3}}):_vm._e(),(_vm.lcl3 > 0)?_c('img',{class:['__imgCheck'],attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])])],1)],1),_c('div',{class:[
            '__divContenedor',
            { __contenedorActivo: _vm.lcl4 > 0 },
            { __error: _vm.errorAct } ]},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('p',[_vm._v("Contenedor")]),_c('p',[_vm._v("40' NOR")]),_c('p',{class:['__infoContenedor'],on:{"click":function($event){return _vm.activeInfo(4)}}},[_vm._v(" Ver detalles ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('p',[_vm._v("Cantidad")]),_c('v-text-field',{ref:"lcl4",attrs:{"type":"number","solo":"","min":"0","dense":""},on:{"change":_vm.confir4},model:{value:(_vm.lcl4),callback:function ($$v) {_vm.lcl4=$$v},expression:"lcl4"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:['__divImg', { __contenedorActivo: _vm.lcl4 > 0 }]},[(_vm.lcl4 <= 0)?_c('img',{attrs:{"src":require("../../public/assets/img/container-inactive.svg"),"alt":""},on:{"click":_vm.alcl4}}):_vm._e(),(_vm.lcl4 > 0)?_c('img',{class:['__imgCheck'],attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])])],1)],1),_c('v-btn',{attrs:{"block":"","color":"success","rounded":""},on:{"click":_vm.verifyContainer}},[_vm._v("Continuar")])],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"__imgInfo",attrs:{"color":"success"}},[_vm._v(" mdi-information-outline ")])],1),_c('v-col',[_c('p',{class:['__titleMain']},[_vm._v("Debe seleccionar al menos 1 contenedor")])])],1)],1),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{class:['text-center', '__divSheet']},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" Cerrar ")]),_c('div',{staticClass:"py-3"},[(_vm.info1 == 1)?_c('informacion20std'):_vm._e(),(_vm.info1 == 2)?_c('informacion40std'):_vm._e(),(_vm.info1 == 3)?_c('informacion40hc'):_vm._e(),(_vm.info1 == 4)?_c('informacion40nor'):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }