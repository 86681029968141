<template>
  <div>
    <p :class="['__titleMain']"><!-- Agregar Servicios Adicionales --></p>
    <v-container :class="['__divContainerServices']">
      <v-row>
        <v-col
          @click="activeService1"
          :class="[
            '__divServices',
            { __activeServices: service1 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>Gastos {{ stringPort }} y de almacenamiento aduanero</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service1"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service1"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="[
            '__divServices',
            { __activeServices: service2 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row @click="activeService2">
            <v-col cols="8">
              <p>Cálculo de impuestos y permisos de aduana</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service2"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service2"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
          <v-row dense v-if="service2">
            <v-col cols="12" md="8">
              <v-autocomplete
                v-model="impuestoSelected"
                :items="itemsImpuestos"
                item-value="id"
                item-text="tipo_mercancia"
                label="Producto"
                return-object
                solo
                dense
              >
                <template
                  v-slot:item="{ item }"
                  @click="_obtenerData(item.ente_regulatorio)"
                >
                  <v-list-item-avatar
                    @click="_obtenerData(item.ente_regulatorio)"
                    color="indigo"
                    class="text-h5 font-weight-light white--text"
                  >
                    {{ item.tipo_mercancia.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      @click="_obtenerData(item.ente_regulatorio)"
                      v-text="item.tipo_mercancia"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="'Regulador: ' + item.ente_regulatorio"
                    ></v-list-item-subtitle>
                    <!--  <v-list-item-subtitle
                      v-text="item.descripcion_producto"
                    ></v-list-item-subtitle> -->
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <p :class="['__pReguladorN']">
                Si Tu Producto <b>NO APARECE</b> elegir <b>CARGA GENERAL</b>.
              </p>
              <p v-if="Regulador" :class="['__pRegulador']">
                Permiso gubernamental adicional:
                {{ Regulador }}
              </p>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                solo
                suffix="USD"
                label="Valor de la mercancía $"
                type="number"
                v-model="valorMercancia"
              ></v-text-field>
              <p :class="['__pReguladorN']">
                Ingresar valor exacto, SIN DECIMALES
              </p>
            </v-col>
            <v-col cols="12" md="12">
              <v-row dense>
                <v-col cols="4">
                  <v-autocomplete
                    :items="itemsPrevio"
                    item-text="name"
                    item-value="id"
                    v-model="selectedPrevio"
                    solo
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="8">
                  <p>¿Ha realizado importaciones previamente?</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          :class="[
            '__divServices',
            { __activeServices: service3 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row @click="activeService3">
            <v-col cols="8">
              <p>Transporte a Domicilio</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service3"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service3"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
          <v-row dense v-if="service3">
            <v-col cols="6">
              <v-autocomplete
                :items="itemsProvincia"
                item-value="id"
                item-text="nombre"
                v-model="selectedProvincia"
                solo
                dense
                label="Provincia"
                return-object
                @change="_getDistritos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="itemsDistrito"
                item-value="id"
                item-text="nombre"
                v-model="selectedDistrito"
                dense
                solo
                return-object
                label="Distrito"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          @click="activeService4"
          :class="[
            '__divServices',
            { __activeServices: service4 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>Seguro de mercancia</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service4"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service4"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col
          @click="activeService5"
          :class="[
            '__divServices',
            { __activeServices: service5 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>No deseo agregar ningun servicios</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service5"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service5"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col> -->
        <v-btn
          block
          :class="['__btnBoott']"
          color="success"
          @click="verifyServicios"
          rounded
          >Continuar</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ServiciosContent",
  data: () => {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,

      errorAct: false,
      stringPort: "portuarios",

      impuestoSelected: {
        id: 29487,
        tipo_mercancia: localStorage.getItem("selectedPermiso"),
      },
      valorMercancia: localStorage.getItem("ValorMercancia"),
      selectedPrevio: parseInt(localStorage.getItem("selectedPrevio")),
      selectedProvincia: {
        id: parseInt(localStorage.getItem("selectedProvincia")),
        nombre: localStorage.getItem("selectedProvinciaText"),
      },
      selectedDistrito: {
        id: parseInt(localStorage.getItem("selectedDistrito")),
        nombre: localStorage.getItem("selectedDistritoText"),
      },
      itemsProvincia: [],
      itemsDistrito: [],
      Regulador: localStorage.getItem("Regulador"),

      itemsImpuestos: [],
      itemsPrevio: [
        { id: 1, name: "Si" },
        { id: 0, name: "No" },
      ],
    };
  },
  created() {
    this._validport();
    this._getPermisos();
    this._getProvincias();
    this._validaServicios();
  },
  methods: {
    _activeError() {
      this.errorAct = true;
      setTimeout(() => {
        this.errorAct = false;
      }, 5000);
    },
    _validport() {
      let typeSer = localStorage.getItem("TipodeContenedor");

      if (typeSer == "LCL" || typeSer == "FCL") {
        this.stringPort = "portuarios";
      } else if (typeSer == "AEREO") {
        this.stringPort = "aeroportuarios";
      }
    },

    _validaServicios() {
      localStorage.setItem("Servicio1", false);
      localStorage.setItem("Servicio2", false);
      localStorage.setItem("Servicio3", false);
      localStorage.setItem("Servicio4", false);
      localStorage.setItem("Servicio5", false);

      localStorage.setItem("selectedPermiso", "0");

      localStorage.setItem("selectedPermisoId", "0");
      localStorage.setItem("selectedPermisoText", "0");
      localStorage.setItem("ValorMercancia", "0");
      localStorage.setItem("selectedPrevio", "0");

      if (this.service3 == true) {
        localStorage.setItem("selectedProvincia", this.selectedProvincia.id);
        localStorage.setItem(
          "selectedProvinciaText",
          this.selectedProvincia.nombre
        );
        localStorage.setItem("selectedDistrito", this.selectedDistrito.id);
        localStorage.setItem(
          "selectedDistritoText",
          this.selectedDistrito.nombre
        );
      }
      if (localStorage.getItem("Servicio1") == "true") {
        this.service1 = true;
      } else {
        this.service1 = false;
      }
      if (localStorage.getItem("Servicio2") == "true") {
        this.service2 = true;
      } else {
        this.service2 = false;
      }

      if (localStorage.getItem("Servicio3") == "true") {
        this.service3 = true;
      } else {
        this.service3 = false;
      }
      if (localStorage.getItem("Servicio4") == "true") {
        this.service4 = true;
      } else {
        this.service4 = false;
      }
      if (localStorage.getItem("Servicio5") == "true") {
        this.service5 = true;
      } else {
        this.service5 = false;
      }
    },
    _getPermisos() {
      let self = this;
      var config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/get_merca_per",
        headers: {
          "Content-Type": "text/plain",
        },
      };
      axios(config)
        .then(function (response) {
          let dataGen = JSON.parse(JSON.stringify(response.data));
          let itemsImpuestosGen = dataGen.data.r_dat;
          itemsImpuestosGen.map(function (item) {
            if (
              item.paises_id ==
              parseInt(localStorage.getItem("PaisSelecteEndId"))
            ) {
              self.itemsImpuestos.push({
                paises_id: item.paises_id,
                tipo_mercancia: item.tipo_mercancia,
                ente_regulatorio: item.ente_regulatorio,
                descripcion_producto: item.descripcion_producto,
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _getProvincias() {
      let self = this;
      let dataPost = {
        prov: [
          localStorage.getItem("PaisEndId"),
          "MARITIMO " + localStorage.getItem("TipodeContenedor"),
        ],
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/get_prov_mar",
        headers: {
          "Content-Type": "application/json",
        },
        data: dataPost,
      };
      axios(config)
        .then(function (response) {
          let dataGen = JSON.parse(JSON.stringify(response.data));
          self.itemsProvincia = dataGen.data.r_dat;

          if (localStorage.getItem("selectedProvincia")) {
            self._getDistritos();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _getDistritos() {
      let self = this;
      let dataPost = {
        prov: [
          self.selectedProvincia.id,
          "MARITIMO " + localStorage.getItem("TipodeContenedor"),
        ],
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/get_dis_mar",
        headers: {
          "Content-Type": "application/json",
        },
        data: dataPost,
      };
      axios(config)
        .then(function (response) {
          let dataGen = JSON.parse(JSON.stringify(response.data));
          self.itemsDistrito = dataGen.data.r_dat;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _obtenerData(data) {
      localStorage.setItem("Regulador", data);
      this.Regulador = data;
    },
    verifyServicios() {
      localStorage.setItem("Servicio1", this.service1);
      localStorage.setItem("Servicio2", this.service2);
      localStorage.setItem("Servicio3", this.service3);
      localStorage.setItem("Servicio4", this.service4);
      localStorage.setItem("Servicio5", this.service5);

      if (this.service2 == true) {
        localStorage.setItem(
          "selectedPermiso",
          this.impuestoSelected.tipo_mercancia
        );
        console.log(this.impuestoSelected);
        localStorage.setItem("selectedPermisoId", this.impuestoSelected.id);
        localStorage.setItem(
          "selectedPermisoText",
          this.impuestoSelected.ente_regulatorio
        );
        localStorage.setItem("ValorMercancia", this.valorMercancia);
        localStorage.setItem("selectedPrevio", this.selectedPrevio);
      }

      if (this.service3 == true) {
        localStorage.setItem("selectedProvincia", this.selectedProvincia.id);
        localStorage.setItem(
          "selectedProvinciaText",
          this.selectedProvincia.nombre
        );
        localStorage.setItem("selectedDistrito", this.selectedDistrito.id);
        localStorage.setItem(
          "selectedDistritoText",
          this.selectedDistrito.nombre
        );
      }

      if (
        (this.service1 == false) &
        (this.service2 == false) &
        (this.service3 == false) &
        (this.service4 == false) &
        (this.service5 == false)
      ) {
        this.$swal({
          title: "ATENCIÓN!!!",
          icon: "error",
          text: "Debe seleccionar al menos un item",
          confirmButtonText: `Ok`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this._activeError();
          } else if (result.isDenied) {
            this.$swal("Changes are not saved", "", "info");
          }
        });
      } else {
        this.$router.push({ name: "Presupuesto" });
      }
    },
    activeService1() {
      this.service1 = !this.service1;
      this.service5 = false;
    },
    activeService2() {
      this.service2 = !this.service2;
      this.service5 = false;
    },
    activeService3() {
      this.service3 = !this.service3;
      this.service5 = false;
    },
    activeService4() {
      this.service4 = !this.service4;
      this.service5 = false;
    },
    activeService5() {
      this.service1 = false;
      this.service2 = false;
      this.service3 = false;
      this.service4 = false;
      this.service5 = !this.service5;
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__btnBoott {
  margin-bottom: 110px !important;
}

.__error {
  border: solid 1px red;
}
.__activeServices {
  background: #24587b !important;
  color: white;
  font-weight: 400;
}

.__pRegulador {
  margin-top: -18px;
  color: #89c9d4;
}

.__pReguladorN {
  margin-top: -18px;
  font-size: 14px;
  color: white;
}
.__divServices {
  background: #e0e7ec;
  margin-bottom: 5px;
  border-radius: 15px;

  img {
    max-width: 20%;

    float: right;

    @media (max-width: 800px) {
      max-width: 40%;
    }
  }
}
</style>
