<template>
  <div>
    <div>
      <v-container>
        <v-row>
          <v-col lg="6" :class="'__divColLeft'">
            <v-btn
              :class="['__btnMain']"
              color="primary"
              rounded
              block
              elevation="0"
              x-large
              @click="__continueA()"
            >
              <img src="../../../public/assets/img/airplanecargo.svg" alt="" />
              Importación Áerea
            </v-btn>
            <v-btn
              :class="'__btnMain'"
              color="primary"
              rounded
              block
              elevation="0"
              x-large
              @click="__continue()"
            >
              <img src="../../../public/assets/img/shipcargo.svg" alt="" />
              Importación Marítima
            </v-btn>
          </v-col>
          <v-col :class="'__divColRight'" lg="6">
            <div :class="'__divImportGrupales'">
              <v-container>
                <v-row>
                  <v-col xs="12" lg="6">
                    <img
                      src="../../../public/assets/img/impogrupal.png"
                      alt=""
                    />
                  </v-col>
                  <v-col xs="12" lg="6">
                    <p>Importaciones Grupales o Individuales</p>
                    <v-btn>Conoce más</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomMedia",
  methods: {
    __continue() {
      localStorage.setItem("m1", true);
      this.$router.push({ name: "ImportacionMaritima" });
    },
    __continueA() {
      localStorage.setItem("m1", true);
      this.$router.push({ name: "Aereo" });
    },
  },
};
</script>
<style lang="scss" scoped>
.__divColLeft {
  padding: 5vw 5vw;

  .__btnMain {
    margin-bottom: 10px;
    background: #24587b !important;
    height: 120px !important;
    border-radius: 250px;
    font-size: 1.5vw;

    img {
      max-width: 5vw;
      height: auto;
      margin-right: 10px;
    }
    @media (max-width: 800px) {
      font-size: 3.5vw;

      img {
        max-width: 12vw;
      }
    }
  }

  @media (max-width: 800px) {
    padding: 30px 20px 0px 20px;
  }
}

.__divColRight {
  padding: 5vw 5vw;

  .__divImportGrupales {
    background: #24587b;
    color: white;
    border-radius: 20px;
    height: 150px;

    img {
      border-radius: 10px;
      max-width: 100%;
    }
  }

  @media (max-width: 800px) {
    padding: 30px 20px 0px 20px;
  }
}
</style>
