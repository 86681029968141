<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    this.forceSWupdate();

    localStorage.setItem("m1", false),
      localStorage.setItem("m2", false),
      localStorage.setItem("m3", false),
      localStorage.setItem("m4", false),
      localStorage.setItem("m5", false),
      localStorage.setItem("m6", false),
      localStorage.setItem("a2", false),
      localStorage.setItem("a3", false),
      localStorage.setItem("a4", false),
      localStorage.setItem("a5", false),
      localStorage.setItem("a6", false);
  },
  methods: {
    forceSWupdate() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.update();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
</style>
