<template>
  <div>
    <p :class="['__titleMain']">Contenedor compartido (LCL)</p>
    <v-container>
      <v-form ref="formg" v-model="validg" lazy-validation>
        <v-row>
          <v-col :class="['__divMainTipo']" xs="12">
            <v-row>
              <v-col cols="4" md="12">
                <v-text-field
                  :rules="fieldRules"
                  type="number"
                  v-model="ibultos"
                  label="Bultos"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field
                  :rules="fieldRules"
                  type="number"
                  v-model="ipeso"
                  label="Peso"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="6">
                <v-autocomplete
                  :rules="fieldRules"
                  v-model="selectediPeso"
                  :items="itemsiPeso"
                  label="Unidad de peso"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  :rules="fieldRules"
                  v-model="ivolumen"
                  type="number"
                  label="Volumen"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                  :rules="fieldRules"
                  v-model="selectediVolumen"
                  :items="itemsVolumen"
                  label="Unidad de volumen"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="dialog = !dialog"
                  block
                  :class="['mb-5', '__titleBtnHelp', '__btnMovil']"
                  color="primary"
                  x-large
                  >CLICK AQUÍ SI NECESITAS AYUDA <br />
                  PARA CALCULAR TU VOLUMEN (m<sup>3</sup>)
                </v-btn>
                <v-btn
                  @click="dialog = !dialog"
                  block
                  :class="['mb-5', '__btnDesktop']"
                  color="primary"
                  x-large
                  >CLICK AQUÍ SI NECESITAS AYUDA PARA CALCULAR TU VOLUMEN
                  (m<sup>3</sup>)
                </v-btn>
              </v-col>
            </v-row>

            <v-btn block color="success" @click="validateg()" rounded
              >Continuar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          CALCULADORA VOLUMETRICA
        </v-card-title>

        <v-card-text :class="['pt-5']">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6" md="4">
                <v-autocomplete
                  @change="_getSuffixOne()"
                  :items="itemsUnidades"
                  v-model="selectedUnidades"
                  label="Unidad de longitud"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="4">
                <v-autocomplete
                  @change="_getSuffixTwo()"
                  :items="itemsPeso"
                  v-model="selectedPeso"
                  label="Unidad de peso"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  v-model="nroBultos"
                  :rules="fieldRules"
                  label="Nro. de Bultos"
                ></v-text-field>
              </v-col>
            </v-row>
            <div :class="['__titleMedidas', 'text-center']">
              <p>MEDIDAS POR CADA BULTO</p>
            </div>
            <v-row>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="largo"
                  label="Largo"
                  :rules="fieldRules"
                  type="number"
                  :suffix="suffixOne"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="ancho"
                  label="Ancho"
                  :rules="fieldRules"
                  type="number"
                  :suffix="suffixOne"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="alto"
                  label="Alto"
                  :rules="fieldRules"
                  type="number"
                  :suffix="suffixOne"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  v-model="peso"
                  label="Peso"
                  :rules="fieldRules"
                  type="number"
                  :suffix="suffixTwo"
                ></v-text-field>
              </v-col>

              <v-col :class="['text-center']" cols="12">
                <v-btn
                  @click="validate()"
                  color="orange"
                  :disabled="!valid"
                  dark
                  >Calcular</v-btn
                >
              </v-col>

              <v-col>
                <v-row
                  v-if="itemsResultados.length > 0"
                  :class="['__divItemsCal']"
                  cols="12"
                >
                  <v-col cols="2">
                    <b>Acción</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    <b>Item</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    <b>Bultos</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    <b>Peso</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="4">
                    <b>Volumen</b>
                  </v-col>
                </v-row>
                <v-row
                  :class="['__divItemsCal']"
                  v-for="(items, index) of itemsResultados"
                  :key="index"
                  cols="12"
                >
                  <v-col cols="2">
                    <v-btn
                      @click="_deleteCalculo(index)"
                      x-small
                      fab
                      color="error"
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    {{ index + 1 }}
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    <b>{{ items.bultos }}</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="2">
                    <b>{{ items.peso }} kg</b>
                  </v-col>
                  <v-col :class="['text-center']" cols="4">
                    <b>{{ items.volumen }} m<sup>3</sup></b>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" :class="['__pNota', 'text-justify']">
                <p>
                  Nota: Los valores de Peso y Volumen serán convertidos
                  automáticamente a Kilogramos y Metros respectivamente.
                </p>
              </v-col>
            </v-row>
            <div :class="['__divTotales']">
              <div :class="['text-center']">
                <p :class="['__titleTotal']">TOTAL</p>
              </div>
              <v-row :class="['pl-5', 'pr-5']">
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="TBultos"
                    disabled
                    label="Bultos"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="TPeso"
                    disabled
                    label="Peso"
                    suffix="kg"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="TVolumen"
                    disabled
                    label="Volumen"
                    suffix="m³"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" @click="dialog = !dialog" outlined>
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="_addCalculo()">
            AGREGAR CALCULO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ContenedorCompartido",
  data: () => {
    return {
      valid: true,
      validg: true,
      dialog: false,
      suffixOne: "cm",
      suffixTwo: "kg",
      selectedUnidades: 1,
      selectedPeso: 1,
      nroBultos: 0,
      largo: 0,
      ancho: 0,
      alto: 0,
      peso: 0,
      ibultos: "",
      ipeso: "",
      ivolumen: "",
      TBultos: 0,
      TPeso: 0,
      TVolumen: 0,
      selectediUnidades: 1,
      selectediPeso: 2,
      selectediVolumen: 1,
      itemsResultados: [],
      __result: "",
      __peso: "",

      fieldRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v > 0) || "Este campo es requerido",
      ],
      itemsUnidades: [
        { value: 1, text: "Centímetros" },
        { value: 2, text: "Pulgadas" },
        { value: 3, text: "Pies" },
        { value: 4, text: "Milímetro" },
        { value: 5, text: "Metros" },
      ],
      itemsPeso: [
        { value: 1, text: "Kilogramos" },
        { value: 2, text: "Libras" },
        { value: 3, text: "Toneladas" },
      ],
      itemsiPeso: [
        { value: 2, text: "Kilogramos" },
        { value: 1, text: "Libras" },
        { value: 4, text: "Toneladas" },
      ],
      itemsVolumen: [
        { value: 1, text: "Metros cúbicos" },
        { value: 2, text: "Pies cúbicos" },
      ],
    };
  },
  methods: {
    _getSuffixOne() {
      if (this.selectedUnidades == 1) {
        this.suffixOne = "cm";
      } else if (this.selectedUnidades == 2) {
        this.suffixOne = "in";
      } else if (this.selectedUnidades == 3) {
        this.suffixOne = "ft";
      } else if (this.selectedUnidades == 4) {
        this.suffixOne = "mm";
      } else if (this.selectedUnidades == 5) {
        this.suffixOne = "m";
      }
    },

    validate() {
      this.$refs.form.validate();

      if (this.$refs.form.validate() == true) {
        this._calculoVolumen();
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    validateg() {
      this.$refs.formg.validate();

      if (this.$refs.formg.validate() == true) {
        this._continueData();
      }
    },

    _getSuffixTwo() {
      if (this.selectedPeso == 1) {
        this.suffixTwo = "kg";
      } else if (this.selectedPeso == 2) {
        this.suffixTwo = "lb";
      } else if (this.selectedPeso == 3) {
        this.suffixTwo = "Tn";
      }
    },

    _deleteCalculo(index) {
      this.itemsResultados.splice(index, 1);
      this._calculoTotal();
    },

    _addCalculo() {
      this.ibultos = this.TBultos;
      this.ipeso = this.TPeso;
      this.ivolumen = this.TVolumen;

      this.dialog = false;
    },

    _calculoTotal() {
      this.TBultos = 0;
      this.TVolumen = 0;
      this.TPeso = 0;
      this.itemsResultados.map((item) => {
        this.TBultos = (
          parseFloat(this.TBultos) + parseFloat(item.bultos)
        ).toFixed(2);
        this.TVolumen = (
          parseFloat(this.TVolumen) + parseFloat(item.volumen)
        ).toFixed(2);
        this.TPeso = (parseFloat(this.TPeso) + parseFloat(item.peso)).toFixed(
          2
        );
      });
    },

    _calculoVolumen() {
      if (this.selectedPeso == 1) {
        this.__peso = this.peso * this.nroBultos;
      } else if (this.selectedPeso == 2) {
        let dividir = 1 / 2.2;
        this.__peso = (this.peso * dividir).toFixed(2) * this.nroBultos;
      } else if (this.selectedPeso == 3) {
        this.__peso = (this.peso * 1000).toFixed(2) * this.nroBultos;
      }

      if (this.selectedUnidades == 1) {
        this.__result =
          (this.largo / 100) *
          (this.ancho / 100) *
          (this.alto / 100) *
          this.nroBultos;
      } else if (this.selectedUnidades == 2) {
        this.__result =
          (this.largo / 39.37) *
          (this.ancho / 39.37) *
          (this.alto / 39.37) *
          this.nroBultos;
      } else if (this.selectedUnidades == 3) {
        this.__result =
          (this.largo / 0.3048) *
          (this.ancho / 0.3048) *
          (this.alto / 0.3048) *
          this.nroBultos;
      } else if (this.selectedUnidades == 4) {
        this.__result =
          (this.largo / 1000) *
          (this.ancho / 1000) *
          (this.alto / 1000) *
          this.nroBultos;
      } else if (this.selectedUnidades == 5) {
        this.__result = this.largo * this.ancho * this.alto * this.nroBultos;
      }

      this.itemsResultados.push({
        bultos: this.nroBultos,
        peso: this.__peso,
        volumen: this.__result.toFixed(2),
      });

      this._calculoTotal();

      this.nroBultos = 0;
      this.largo = 0;
      this.alto = 0;
      this.ancho = 0;
      this.peso = 0;

      this.resetValidation();
    },

    _continueData() {
      localStorage.setItem("bultos", this.ibultos);
      localStorage.setItem("peso", this.ipeso);
      localStorage.setItem("volumen", this.ivolumen);
      localStorage.setItem("selectedPeso", this.selectediPeso);
      localStorage.setItem("selectedVolumen", this.selectediVolumen);
      localStorage.setItem("selectedVolumenUnit", 5);

      this.$router.push({ name: "Ruta" });
    },

    inalcl1() {
      this.lcl1 = 0;
    },
    inalcl2() {
      this.lcl2 = 0;
    },
    inalcl3() {
      this.lcl3 = 0;
    },
    inalcl4() {
      this.lcl4 = 0;
    },
    alcl1() {
      this.lcl1 = 1;
    },
    alcl2() {
      this.lcl2 = 1;
    },
    alcl3() {
      this.lcl3 = 1;
    },
    alcl4() {
      this.lcl4 = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.__pNota {
  font-size: 16px;
  color: rgb(82, 82, 82);
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 3.2vw;
  }
}

.__titleBtnHelp {
  @media (max-width: 800px) {
    font-size: 3.4vw;
  }
}

.__btnMovil {
  display: none;
  @media (max-width: 800px) {
    display: inline-block;
  }
}

.__btnDesktop {
  display: inline-block;
  @media (max-width: 800px) {
    display: none;
  }
}

.__divTotales {
  background: #efefef;
  border-radius: 10px;
  padding-top: 10px;
}

.__titleMedidas {
  background: #656565;
  padding-top: 5px;
  padding-bottom: 0px;
  border-radius: 5px;
  color: white;
}

.__titleTotal {
  font-weight: 800;
  font-size: 18px;
}

.__divItemsCal {
  background: rgb(226, 226, 226);
  margin-bottom: 1px;
  border-radius: 10px;
}

.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__divMainTipo {
  padding: 10px;

  .__divContenedor {
    background: #24587b24;
    border-radius: 15px;
    padding: 20px;
    height: 95px;
    margin-bottom: 8px;

    .__divImg {
      background: white;
      text-align: center;
      border-radius: 250px;
      width: 60px;
      height: 60px;
      padding-top: 8px;
      float: right;
    }

    img {
      max-width: 70%;
      cursor: pointer;
    }

    .__imgCheck {
      max-width: 90%;
      cursor: pointer;
    }

    p {
      line-height: 5px;
    }
  }
}

.__contenedorActivo {
  background: #24587b !important;
  color: white;
}
</style>
