<template>
  <div>
    <p :class="['__titleMain']"></p>
    <v-container :class="['__divContainerRuta']">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="2">
              <img
                class="__imgRoute"
                src="../../public/assets/img/route.svg"
                alt=""
              />
            </v-col>
            <v-col>
              <v-col :class="['__divMainTipo']" cols="12">
                <v-autocomplete
                  @change="_getPortEnd()"
                  :items="itemPaisBegin"
                  item-value="id"
                  item-text="puerto"
                  v-model="paisBegin"
                  return-object
                  ref="pais"
                  label="Seleccione el país y puerto de origen"
                ></v-autocomplete>
              </v-col>

              <v-col :class="['__divMainTipo']" cols="12">
                <v-autocomplete
                  :disabled="disabled"
                  @click="_validRoute"
                  :items="itemPaisEnd"
                  item-value="id"
                  item-text="puerto"
                  v-model="paisEnd"
                  return-object
                  label="Seleccione el país y puerto de destino"
                ></v-autocomplete>
              </v-col>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          @click="_siServices()"
          v-if="activeSer"
          :class="['__divServices', '__activeServices', { __error: errorAct }]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>Agregar servicios</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service01"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service01"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          @click="_noServices()"
          v-if="activeSer"
          :class="[
            '__divServices',
            { __activeServices: service5 },
            { __error: errorAct },
          ]"
          cols="12"
        >
          <v-row>
            <v-col cols="8">
              <p>No deseo agregar servicios</p>
            </v-col>
            <v-col cols="4">
              <img
                v-if="!service5"
                src="../../public/assets/img/check-inactive.svg"
                alt=""
              />
              <img
                v-if="service5"
                src="../../public/assets/img/check.svg"
                alt=""
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-btn
            v-if="!activeSer"
            block
            color="success"
            @click="verifyRoute"
            rounded
            >Continuar</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!activeSer">
      <v-row>
        <v-col cols="2">
          <v-icon color="success" class="__imgInfo">
            mdi-information-outline
          </v-icon>
        </v-col>
        <v-col>
          <p :class="['__titleMain']">
            Seleccione el país y puerto tanto en origen como en destino
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import MiniSearch from "minisearch";
export default {
  name: "TipoContenedor",

  data: () => {
    return {
      itemPaisBegin: [],
      itemPaisEnd: [],

      disabled: false,

      arrayPais: [],

      paisSelectedBegin: [],
      paisSelectedEnd: [],

      service01: true,

      paisBegin: {
        id: "",
        puerto: "",
      },
      paisEnd: {
        id: "",
        puerto: "",
      },

      activeSer: false,

      axiosConfig: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
  },
  created() {
    this._getPortBegin();
    if (localStorage.getItem("PaisOrigenId")) {
      this._getPortEnd();
      this.paisBegin = {
        id: parseInt(localStorage.getItem("PaisBeginId")),
        puerto: localStorage.getItem("PaisBeginText"),
      };
      this.paisEnd = {
        id: parseInt(localStorage.getItem("PaisEndId")),
        puerto: localStorage.getItem("PaisEndText"),
      };
    } else {
      localStorage.removeItem("PaisOrigenId");
      localStorage.removeItem("PaisOrigenText");
      localStorage.removeItem("PaisEndId");
      localStorage.removeItem("PaisEndText");
    }
  },
  methods: {
    _noServices() {
      this.$router.push({ name: "Presupuesto" });
    },
    _siServices() {
      this.$router.push({ name: "Servicios" });
    },
    _showActive() {
      localStorage.setItem("m3", true);
      this.activeSer = true;
    },
    _validRoute() {
      if (this.paisBegin.id == "") {
        this.$swal({
          title: "ATENCIÓN!!!",
          icon: "error",
          text: "Debe seleccionar el puerto de origen",
          confirmButtonText: `Ok`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this._activeError();
          } else if (result.isDenied) {
            this.$swal("Changes are not saved", "", "info");
          }
        });
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    _getPortBegin() {
      let self = this;
      var dataPost;
      var urldat;
      if (localStorage.getItem("TipodeContenedor") == "FCL") {
        dataPost = { word: "", port: "MARITIMO FCL" };
        urldat = process.env.VUE_APP_API_URL + "/get_find_port_mar_begin";
      } else if (localStorage.getItem("TipodeContenedor") == "LCL") {
        dataPost = { word: "", port: "MARITIMO LCL" };
        urldat = process.env.VUE_APP_API_URL + "/get_find_port_mar_begin";
      } else if (localStorage.getItem("TipodeContenedor") == "AEREO") {
        dataPost = { word: "" };
        urldat = process.env.VUE_APP_API_URL_AE + "/get_find_port_aereo_begin";
      }

      let config = {
        method: "post",
        url: urldat,
        headers: {
          "Content-Type": "application/json",
        },
        data: dataPost,
      };
      axios(config)
        .then(function (response) {
          let dataGen = JSON.parse(JSON.stringify(response.data));

          self.itemPaisBegin = dataGen.data.r_dat;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _getPortPush() {
      const miniSearch = new MiniSearch({
        fields: ["puerto"],
        storeFields: ["puerto"],
      });

      let docs = [];
      this.itemPaisBegin.map((item) =>
        docs.push({
          id: item.id,
          puerto: item.puerto,
        })
      );

      miniSearch.addAll(docs);

      const buscado = this.$refs.pais.lazySearch;

      let result = miniSearch.search(buscado, { fuzzy: 0.2, prefix: true });
      console.log(result);

      this.itemPaisBegin = result.map(function (item) {
        return {
          id: item["id"],
          puerto: item["puerto"],
        };
      });
    },
    _getPortEnd() {
      this.disabled = false;
      let self = this;
      var dataPost;
      var urldat;
      let head;
      //var port = localStorage.getItem("PaisOrigenId");
      if (localStorage.getItem("TipodeContenedor") == "FCL") {
        dataPost = { word: "", port: "MARITIMO FCL" };
        head = "text/plane";
        urldat = process.env.VUE_APP_API_URL + "find_port_to_mar";
      } else if (localStorage.getItem("TipodeContenedor") == "LCL") {
        dataPost = { word: "", port: "MARITIMO LCL" };
        head = "text/plane";
        urldat = process.env.VUE_APP_API_URL + "find_port_to_mar";
      } else if (localStorage.getItem("TipodeContenedor") == "AEREO") {
        dataPost = { word: this.paisBegin.id };
        head = "application/json";
        urldat = process.env.VUE_APP_API_URL_AE + "find_port_to_aereo";
      }

      let config = {
        method: "post",
        url: urldat,
        headers: {
          "Content-Type": head,
        },
        data: dataPost,
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          let dataGen = JSON.parse(JSON.stringify(response.data));

          self.itemPaisEnd = dataGen.data.r_dat;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    verifyRoute() {
      localStorage.setItem("PaisOrigenId", this.paisBegin.id);
      localStorage.setItem("PaisOrigenText", this.paisBegin.puerto);
      localStorage.setItem("PaisEndId", this.paisEnd.id);
      localStorage.setItem("PaisEndText", this.paisEnd.puerto);
      let strB = this.paisBegin.puerto;
      let strE = this.paisEnd.puerto;

      this.paisSelectedBegin = strB.split(" - ");
      this.paisSelectedEnd = strE.split(" - ");

      localStorage.setItem("PaisSelecteOrigen", this.paisSelectedBegin[0]);
      localStorage.setItem("PaisSelecteEnd", this.paisSelectedEnd[0]);

      if (this.paisSelectedEnd[0] == "PERÚ") {
        localStorage.setItem("PaisSelecteEndId", 1);
      } else if (this.paisSelectedEnd[0] == "PANAMÁ") {
        localStorage.setItem("PaisSelecteEndId", 43);
      } else if (this.paisSelectedEnd[0] == "VENEZUELA") {
        localStorage.setItem("PaisSelecteEndId", 66);
      }

      if (
        localStorage.getItem("PaisOrigenId") &&
        localStorage.getItem("PaisEndId")
      ) {
        this.activeSer = true;
      } else {
        this.$swal({
          title: "ATENCIÓN!!!",
          icon: "error",
          text: "Debe seleccionar una ruta",
          confirmButtonText: `Ok`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this._activeError();
          } else if (result.isDenied) {
            this.$swal("Changes are not saved", "", "info");
          }
        });
      }
    },
    routerContainer(tipoContainer) {
      localStorage.setItem("TipodeContenedor", tipoContainer);
      this.$router.push({ name: "TipoContenedor" });
    },
    activeService1() {
      this.service1 = !this.service1;
      this.service5 = false;
    },
    activeService2() {
      this.service2 = !this.service2;
      this.service5 = false;
    },
    activeService3() {
      this.service3 = !this.service3;
      this.service5 = false;
    },
    activeService4() {
      this.service4 = !this.service4;
      this.service5 = false;
    },
    activeService5() {
      this.service1 = false;
      this.service2 = false;
      this.service3 = false;
      this.service4 = false;
      this.service5 = !this.service5;
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

.__divContainerRuta {
  padding: 10px 40px;
}

.__divMainTipo {
  padding: 10px;
}

.__imgInfo {
  width: 100%;
  height: 100%;
  font-size: 4vw;

  @media (max-width: 800px) {
    font-size: 10vw;
  }
}
.__activeServices {
  background: #24587b !important;
  color: white;
  font-weight: 400;
}

.__imgRoute {
  max-width: 35%;
  float: right;
  @media (max-width: 800px) {
    max-width: 100%;
    margin-top: 15px;
  }
}

.__divServices {
  background: #e0e7ec;
  margin-bottom: 5px;
  border-radius: 15px;
  cursor: pointer;

  img {
    max-width: 20%;

    float: right;

    @media (max-width: 800px) {
      max-width: 40%;
    }
  }
}
</style>
