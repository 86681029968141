<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="2">
          <v-icon color="success" class="__imgInfo">
            mdi-information-outline
          </v-icon>
        </v-col>
        <v-col>
          <p :class="['__titleMain']">
            Seleccione el tipo de contenedor en el cual desea que su mercancía
            sea transportada
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col :class="['__divMainTipo']" cols="12" md="6">
          <div @click="routerContainer('FCL')" :class="['__divMainInt']">
            <v-row>
              <!-- <v-col cols="3" md="4">
                <img
                  width="100%"
                  src="../../public/assets/img/contenedor_completo.png"
                  alt=""
                />
              </v-col> -->
              <v-col>
                <div class="__imgContenedor">
                  <img
                    src="../../public/assets/img/contenedor_completo.png"
                    alt=""
                  />
                </div>
                <p :class="['__titleContenedor']">Contenedor completo</p>
                <p>
                  En caso de que requiera que su mercancía sea transportada en
                  un contenedor solamente para usted
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col :class="['__divMainTipo']" cols="12" md="6">
          <div @click="routerContainer('LCL')" :class="['__divMainInt']">
            <v-row>
              <!-- <v-col cols="3" md="4">
                <img
                  width="100%"
                  src="../../public/assets/img/contenedor_compartido.png"
                  alt=""
                />
               
              </v-col> -->
              <v-col>
                <div class="__imgContenedor">
                  <img
                    src="../../public/assets/img/contenedor_compartido.png"
                    alt=""
                  />
                </div>
                <p :class="['__titleContenedor']">Contenedor compartido</p>

                <p></p>
                <p>
                  En caso de que requiera que su mercancía sea transportada en
                  un contenedor compartido con otros importadores o exportadores
                </p>
              </v-col>
            </v-row>

            <br />
          </div>
        </v-col>
        <v-col :class="['__divMainTipoG']" cols="12" md="12">
          <div @click="routerContainer('LCL')" :class="['__divMainIntG']">
            <v-row>
              <!-- <v-col cols="3" md="4">
                <img
                  width="100%"
                  src="../../public/assets/img/contenedor_compartido.png"
                  alt=""
                />
               
              </v-col> -->
              <v-col>
                <div class="__imgContenedor">
                  <img src="../../public/assets/img/informacion.svg" alt="" />
                </div>
                <p :class="['__titleContenedor']">Importación Grupal</p>

                <p></p>
                <p>
                  Si quieres realizar una importación y cuentas con poco
                  capital, esta es la mejor opción para ti
                </p>
              </v-col>
            </v-row>

            <br />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TipoContenedor",
  methods: {
    routerContainer(tipoContainer) {
      localStorage.setItem("m2", true);
      localStorage.setItem("TipodeContenedor", tipoContainer);
      this.$router.push({ name: "TipoContenedor" });
    },
  },
};
</script>

<style lang="scss" scoped>
.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 3.5vw;
  }
}

.__imgInfo {
  width: 100%;
  height: 100%;
  font-size: 6vw;

  @media (max-width: 800px) {
    font-size: 15vw;
  }
}

.__divMainTipo {
  padding: 10px;
}

.__contenedorActivo {
  background: #24587b !important;
  color: white;
}

.__divMainInt {
  background: #ececec;
  padding: 15px;
  border-radius: 10px;
  height: 28vh;

  cursor: pointer;

  &:hover {
    background: #24587b !important;
    color: white;

    .__titleContenedor {
      color: white;
    }
  }

  .__titleContenedor {
    font-weight: 500;
    font-size: 2.2vw;
    color: #264e6b;
  }
  p {
    font-size: 16px;
  }

  .__imgContenedor {
    img {
      max-width: 25%;
      float: right;

      @media (max-width: 800px) {
        max-width: 18%;
        float: right;
      }
    }
  }

  @media (max-width: 800px) {
    height: 20vh;

    .__titleContenedor {
      font-size: 6.5vw;
    }

    p {
      font-size: 3.5vw;
    }
  }
}

.__divMainIntG {
  background: #ececec;
  padding: 15px;
  border-radius: 10px;
  height: 20vh;

  cursor: pointer;

  &:hover {
    background: #24587b !important;
    color: white;

    .__titleContenedor {
      color: white;
    }
  }

  .__titleContenedor {
    font-weight: 500;
    font-size: 2.2vw;
    color: #264e6b;
  }
  p {
    font-size: 16px;
  }

  .__imgContenedor {
    img {
      max-width: 20%;
      float: right;

      @media (max-width: 800px) {
        max-width: 14%;
        float: right;
      }
    }
  }

  @media (max-width: 800px) {
    height: 20vh;

    .__titleContenedor {
      font-size: 6.5vw;
    }

    p {
      font-size: 3.5vw;
    }
  }
}
</style>
