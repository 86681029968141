var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{class:['__titleMain']}),_c('v-container',{class:['__divContainerServices']},[_c('v-row',[_c('v-col',{class:[
          '__divServices',
          { __activeServices: _vm.service1 },
          { __error: _vm.errorAct } ],attrs:{"cols":"12"},on:{"click":_vm.activeService1}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("Gastos "+_vm._s(_vm.stringPort)+" y de almacenamiento aduanero")])]),_c('v-col',{attrs:{"cols":"4"}},[(!_vm.service1)?_c('img',{attrs:{"src":require("../../public/assets/img/check-inactive.svg"),"alt":""}}):_vm._e(),(_vm.service1)?_c('img',{attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])],1)],1),_c('v-col',{class:[
          '__divServices',
          { __activeServices: _vm.service2 },
          { __error: _vm.errorAct } ],attrs:{"cols":"12"}},[_c('v-row',{on:{"click":_vm.activeService2}},[_c('v-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("Cálculo de impuestos y permisos de aduana")])]),_c('v-col',{attrs:{"cols":"4"}},[(!_vm.service2)?_c('img',{attrs:{"src":require("../../public/assets/img/check-inactive.svg"),"alt":""}}):_vm._e(),(_vm.service2)?_c('img',{attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])],1),(_vm.service2)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsImpuestos,"item-value":"id","item-text":"tipo_mercancia","label":"Producto","return-object":"","solo":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"},on:{"click":function($event){return _vm._obtenerData(item.ente_regulatorio)}}},[_vm._v(" "+_vm._s(item.tipo_mercancia.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.tipo_mercancia)},on:{"click":function($event){return _vm._obtenerData(item.ente_regulatorio)}}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Regulador: ' + item.ente_regulatorio)}})],1)]}}],null,false,2780823961),model:{value:(_vm.impuestoSelected),callback:function ($$v) {_vm.impuestoSelected=$$v},expression:"impuestoSelected"}}),_c('p',{class:['__pReguladorN']},[_vm._v(" Si Tu Producto "),_c('b',[_vm._v("NO APARECE")]),_vm._v(" elegir "),_c('b',[_vm._v("CARGA GENERAL")]),_vm._v(". ")]),(_vm.Regulador)?_c('p',{class:['__pRegulador']},[_vm._v(" Permiso gubernamental adicional: "+_vm._s(_vm.Regulador)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","solo":"","suffix":"USD","label":"Valor de la mercancía $","type":"number"},model:{value:(_vm.valorMercancia),callback:function ($$v) {_vm.valorMercancia=$$v},expression:"valorMercancia"}}),_c('p',{class:['__pReguladorN']},[_vm._v(" Ingresar valor exacto, SIN DECIMALES ")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsPrevio,"item-text":"name","item-value":"id","solo":"","dense":""},model:{value:(_vm.selectedPrevio),callback:function ($$v) {_vm.selectedPrevio=$$v},expression:"selectedPrevio"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("¿Ha realizado importaciones previamente?")])])],1)],1)],1):_vm._e()],1),_c('v-col',{class:[
          '__divServices',
          { __activeServices: _vm.service3 },
          { __error: _vm.errorAct } ],attrs:{"cols":"12"}},[_c('v-row',{on:{"click":_vm.activeService3}},[_c('v-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("Transporte a Domicilio")])]),_c('v-col',{attrs:{"cols":"4"}},[(!_vm.service3)?_c('img',{attrs:{"src":require("../../public/assets/img/check-inactive.svg"),"alt":""}}):_vm._e(),(_vm.service3)?_c('img',{attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])],1),(_vm.service3)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsProvincia,"item-value":"id","item-text":"nombre","solo":"","dense":"","label":"Provincia","return-object":""},on:{"change":_vm._getDistritos},model:{value:(_vm.selectedProvincia),callback:function ($$v) {_vm.selectedProvincia=$$v},expression:"selectedProvincia"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsDistrito,"item-value":"id","item-text":"nombre","dense":"","solo":"","return-object":"","label":"Distrito"},model:{value:(_vm.selectedDistrito),callback:function ($$v) {_vm.selectedDistrito=$$v},expression:"selectedDistrito"}})],1)],1):_vm._e()],1),_c('v-col',{class:[
          '__divServices',
          { __activeServices: _vm.service4 },
          { __error: _vm.errorAct } ],attrs:{"cols":"12"},on:{"click":_vm.activeService4}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('p',[_vm._v("Seguro de mercancia")])]),_c('v-col',{attrs:{"cols":"4"}},[(!_vm.service4)?_c('img',{attrs:{"src":require("../../public/assets/img/check-inactive.svg"),"alt":""}}):_vm._e(),(_vm.service4)?_c('img',{attrs:{"src":require("../../public/assets/img/check.svg"),"alt":""}}):_vm._e()])],1)],1),_c('v-btn',{class:['__btnBoott'],attrs:{"block":"","color":"success","rounded":""},on:{"click":_vm.verifyServicios}},[_vm._v("Continuar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }