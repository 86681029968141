<template>
  <div>
    <div :class="['__divTopMain']">
      <v-container>
        <v-row>
          <v-col :class="['__topColLeft']">
            <div>
              <v-btn @click="back()" small fab dark color="cyan">
                <img src="../../public/assets/img/back.svg" alt="" />
              </v-btn>
            </div>
            <p>{{ title }}</p>
            <p class="__subtitle">{{ subtitle }}</p>
          </v-col>

          <img :src="ruta" alt="" />
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "TopbarMain",
  props: {
    title: String,
    subtitle: String,
    ruta: String,
  },

  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.__divTopMain {
  background: #24587b;
  color: white;
  height: 180px;

  p {
    margin-top: 10px;
    font-size: 22px;
  }

  .__subtitle {
    margin-top: -15px;
    font-size: 18px;
    color: #b9e3ff;
  }

  img {
    position: relative;
    right: 0;
    top: 0;
    max-width: 12%;
  }

  @media (max-width: 800px) {
    img {
      position: relative;
      right: 0;
      top: 0;
      max-width: 20%;
    }
  }

  .__topColLeft {
    img {
      max-width: 4%;
    }
  }
}
</style>
