<template>
  <div class="__background">
    <div :class="['__bardLeft']">
      <p>PANEL DE NAVEGACIÓN</p>

      <v-btn
        :disabled="!m1"
        :to="{ name: 'Home' }"
        class="__btnMain"
        block
        text
        dark
        ><v-icon>mdi-home</v-icon> INICIO</v-btn
      >
      <v-btn
        :disabled="!m2"
        :to="{ name: 'ImportacionMaritima' }"
        class="__btnMain"
        block
        text
        dark
        ><v-icon>mdi-folder-open</v-icon> TIPO DE CONTENEDOR</v-btn
      >
      <v-btn
        v-if="tipoContenedor == 'FCL'"
        :to="{ name: 'TipoContenedor' }"
        class="__btnMainInt"
        block
        text
        dark
        ><v-icon>mdi-file</v-icon> COMPLETO</v-btn
      >
      <v-btn
        v-if="tipoContenedor == 'LCL'"
        :to="{ name: 'TipoContenedor' }"
        class="__btnMainInt"
        block
        text
        dark
        ><v-icon>mdi-file</v-icon> COMPARTIDO</v-btn
      >
      <v-btn
        :disabled="!m3"
        :to="{ name: 'Ruta' }"
        class="__btnMain __active"
        block
        text
        dark
        ><v-icon>mdi-google-maps</v-icon> RUTA</v-btn
      >
      <v-btn
        :disabled="!m4"
        :to="{ name: 'Servicios' }"
        class="__btnMain"
        block
        text
        dark
        ><v-icon>mdi-layers</v-icon> SERVICIOS</v-btn
      >

      <v-btn
        :disabled="!m5"
        :to="{ name: 'Presupuesto' }"
        class="__btnMain"
        block
        text
        dark
        ><v-icon>mdi-file-pdf</v-icon> PRESUPUESTO</v-btn
      >

      <v-btn
        block
        color="teal accent-2"
        :to="{ name: 'Home' }"
        class="__btnBottom"
        >NUEVA COTIZACIÓN</v-btn
      >
    </div>
    <div :class="['__divTopMain']">
      <TopbarMain
        title="Importación Maritima"
        subtitle="Seleccione la ruta"
        :ruta="imagen"
      />
      <div :class="['__divMainContent']">
        <Ruta />
      </div>
      <!-- <div :class="['__divPreguntas']">
        <PreguntasFrecuentes />
      </div> -->
      <FooterMain />
    </div>
  </div>
</template>

<script>
import TopbarMain from "@/components/Topbar.vue";
import Ruta from "@/components/Ruta.vue";
//import PreguntasFrecuentes from "@/components/PreguntasFrecuentes.vue";
import FooterMain from "@/components/FooterMain.vue";

import dataImg from "../../../public/assets/img/ship.svg";

export default {
  name: "TipoServicio",
  data: () => {
    return {
      imagen: dataImg,
      tipoContenedor: localStorage.getItem("TipodeContenedor"),
    };
  },
  components: {
    TopbarMain,
    Ruta,
    //PreguntasFrecuentes,
    FooterMain,
  },
};
</script>

<style lang="scss" scoped>
.__bardLeft {
  width: 350px;
  position: fixed;
  background: #24587b;
  box-shadow: 0px -1px 40px -12px rgba(0, 0, 0, 0.75);
  height: 100%;
  padding-top: 10px;
  display: block;

  .__btnMain {
    text-decoration: none;
    color: white;
    border-radius: 0px;
    height: 50px;
    display: block !important;
    width: 100%;
    cursor: pointer;
    background: none;
    padding: 15px 20px;
    text-align: left !important;
    justify-content: left;

    &:hover {
      background: #dbf0ff;
      color: black;
      font-weight: bold;
    }
  }

  .__active {
    background: #46e49a !important;
    color: black !important;
    font-weight: bold;
  }

  .__btnMainInt {
    text-decoration: none;
    color: white;

    display: block !important;
    text-align: left !important;
    width: 100%;
    border-radius: 0px;
    height: 50px;
    cursor: pointer;
    background: none;
    padding: 15px 50px;
    justify-content: left;

    &:hover {
      background: #dbf0ff;
      color: black;
    }
  }

  .__btnBottom {
    text-decoration: none;

    font-weight: bold;
    display: block !important;
    width: 100%;
    height: 50px;
    cursor: pointer;

    padding: 15px 20px;
    position: absolute;
    bottom: 0;
    border-radius: 0px;

    &:hover {
      background: #dbf0ff;
      color: black;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }

  p {
    text-align: center;
    color: white;
  }
}

.__divTopMain {
  max-width: 800px;
  margin: auto;
}

.__background {
  background-image: url("../../../public/assets/img/background_main.png");
  background-size: 100%;
  background-attachment: fixed;
}

.__divMainContent {
  margin-top: -40px;
  min-height: 86vh;
  background: white;
  border-radius: 25px 25px 0px 0px;
}

.__divPreguntas {
  background: #f9f9f9;
  border-radius: 25px 25px 0px 0px;
  text-align: center;
  padding: 50px 20px;
  height: 51vh;
  box-shadow: inset 0px 34px 50px -52px rgba(0, 0, 0, 1);
}
</style>
