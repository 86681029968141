<template>
  <div>
    <v-container :class="['__divContainerRuta']">
      <p class="text-center">
        <b>Validez de la tarifa</b> <br />
        desde <b>{{ fecha_actual }}</b> hasta <b>{{ fecha_actual }}</b>
      </p>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-btn block color="primary" @click="generateReport()" rounded
            >Descargar Cotización</v-btn
          >
        </v-col>
        <v-col cols="12" md="12">
          <v-btn block color="success" rounded
            >Contactar a un asesor de aduanas</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col :class="['__divMainTipo']" cols="12">
          <v-row :class="['__itemsDescription']">
            <v-col cols="12" md="6">
              <ul>
                <li>
                  Trasporte: <span>{{ desTransporte }}</span>
                </li>
                <li>
                  Tipo:<span>{{ desTipo }}</span>
                </li>
                <li>
                  Cantidad:<span>{{ desCantidad }}</span>
                </li>
                <li>
                  Gasto portuario y almacenamiento aduanero:<span>{{
                    desGastos
                  }}</span>
                </li>
                <li>
                  Tipo de producto:<span>{{ desProducto }}</span>
                </li>
                <li>
                  Impuestos:<span>{{ desImpuestos }}</span>
                </li>
              </ul>
            </v-col>
            <v-col v-if="statusDeta" cols="12" md="6">
              <ul>
                <li>
                  Valor de mercancía:<span>{{ desValor }}</span>
                </li>
                <li>
                  Impuestos de Aduana:<span>{{ desImpuestoAduana }}</span>
                </li>
                <li>
                  Trasporte a Domicilio:<span>{{ desTrasporteDomicilio }}</span>
                </li>
                <li>
                  Seguro de Mercancía:<span>{{ desSeguro }}</span>
                </li>
                <li>
                  Permiso Gubernamental Adicional:<span>{{ desPermiso }}</span>
                </li>
              </ul>
            </v-col>
            <p @click="_verMasDet" :class="['__verMasDetalles']">
              {{ detallesGenerales }}
            </p>
            <v-col cols="12">
              <div :class="['__divServicio']">
                <p :class="['__titleServicio']">
                  SERVICIOS LOGÍSTICOS PIC CARGO
                </p>
                <div :class="['__divContentServicio']">
                  <v-row>
                    <v-col cols="12" md="7" :class="['__divItemServicio']">
                      <p style="font-size: 14px"><b>Incluye</b></p>
                      <p style="padding-left: 10px">◉ FLETE MARÍTIMO</p>
                      <p style="padding-left: 10px">
                        ◉ THCD (Manejo de naviera en Destino)
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'TRUE'"
                      >
                        ◉ VISTOS BUENOS
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'TRUE'"
                      >
                        ◉ GATE IN(Recepción de contenedor vacio en el puerto
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'TRUE'"
                      >
                        ◉ ALMACEN ADUANERO
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service2 == 'TRUE'"
                      >
                        ◉ HONORARIOS DE AGENCIA DE ADUANA
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service3 == 'TRUE'"
                      >
                        ◉ TRANSPORTE A FABRICA IMPORTADOR
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service4 == 'TRUE'"
                      >
                        ◉ SEGURO DE MERCANCIA
                      </p>

                      <p style="font-size: 14px"><b>No incluye</b></p>

                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'FALSE'"
                      >
                        ◉ VISTOS BUENOS
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'FALSE'"
                      >
                        ◉ GATE IN(Recepción de contenedor vacio en el puerto
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service1 == 'FALSE'"
                      >
                        ◉ ALMACEN ADUANERO
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service2 == 'FALSE'"
                      >
                        ◉ HONORARIOS DE AGENCIA DE ADUANA
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service3 == 'FALSE'"
                      >
                        ◉ TRANSPORTE A FABRICA IMPORTADOR
                      </p>
                      <p
                        style="padding-left: 10px"
                        v-if="this.service4 == 'FALSE'"
                      >
                        ◉ SEGURO DE MERCANCIA
                      </p>
                    </v-col>
                    <v-col cols="12" md="5" :class="['__divTotalServicio']">
                      <!-- <p>TOTAL SERVICIOS LOGISTICOS PIC CARGO (Inc. IGV)</p> -->
                      <p :class="['__titleCosto']">
                        {{ costoLogistico }},<strong class="_curren">00</strong>
                        USD
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="12" v-if="costoMinimo">
              <div :class="['__divServicio']">
                <p :class="['__titleServicio']">IMPUESTOS DE ADUANA SUNAT</p>
                <div :class="['__divContentServicio']">
                  <div>
                    <v-row>
                      <v-col cols="12" md="7" :class="['__divTotalEscenario']">
                        <p :class="['__TitleEscenario']">
                          Tu Mejor Escenario de Aduana
                        </p>
                        <p :class="['__SubTitleEscenario']">
                          Aplicando 0% de ADVALOREM y Certificado de Origen
                        </p>
                      </v-col>
                      <v-col cols="12" md="5" :class="['__divTotalServicio']">
                        <p :class="['__titleCosto']">
                          {{ costoMinimo }},<strong class="_curren">00</strong>
                          USD
                        </p>
                        <p
                          @click="_verCalculo(1)"
                          :class="['__verCalculosImp']"
                        >
                          Ver calculos del impuesto
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <div></div>
                </div>
              </div>
            </v-col>
            <v-col v-if="costoMinimo" cols="12">
              <div :class="['__divTotales']">
                <p :class="['__titleServicio']">TOTAL</p>
                <div :class="['__divContentServicio']">
                  <div>
                    <v-row>
                      <v-col cols="8" md="7" :class="['__divTotalEscenario']">
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col cols="3" :class="['__mobilStatus']">
                            <img width="100px" :src="logoPic" alt="" />
                          </v-col>
                          <v-col>
                            <p>SERVICIO LOGISTICOS PIC CARGO</p>
                            <span>+</span>
                          </v-col>
                        </v-row>
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col :class="['__mobilStatus']" cols="3">
                            <img width="100px" :src="logo" alt="" />
                          </v-col>
                          <v-col>
                            <p>IMPUESTOS DE ADUANA MINIMO</p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4" md="5" :class="['__divTotalServicio']">
                        <p :class="['__titleCosto']">
                          {{ costoMinimoTotal }},<strong class="_curren"
                            >00</strong
                          >
                          USD
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="costoMinimo" :class="['__otrosEscenarios']">
              <p @click="_verOtros" :class="['__botonOtro']">
                Otros Escenarios de Aduana
              </p>
              <div v-if="statusOtros" :class="['__divTotales']">
                <p :class="['__titleServicio']">TOTAL MEDIO DOLARES</p>
                <div :class="['__divContentServicio']">
                  <div>
                    <v-row>
                      <v-col
                        @click="_verCalculo(2)"
                        cols="12"
                        :class="['__divTotalEscenario']"
                      >
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col :class="['__mobilStatus']" cols="2">
                            <img width="100px" :src="logoPic" alt="" />
                          </v-col>
                          <v-col cols="9" md="8">
                            <p>SERVICIO LOGISTICOS PIC CARGO</p>
                            <p :class="['__calculosImpuestos']">
                              El costo logistico se mantiene en todos los
                              escenario
                            </p>
                            <span>+</span>
                          </v-col>
                          <v-col>
                            <p>
                              {{ costoLogistico }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col :class="['__mobilStatus']" cols="2">
                            <img width="100px" :src="logo" alt="" />
                          </v-col>
                          <v-col cols="9" md="8">
                            <p>IMPUESTOS DE ADUANA MEDIO</p>
                          </v-col>
                          <v-col>
                            <p>{{ costoMedio }}</p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="7" md="8">
                        <p @click="_verCalculo(2)" :class="['__verCalculos']">
                          Ver calculos del impuesto
                        </p>
                      </v-col>
                      <v-col cols="5" md="4" :class="['__divTotalServicio']">
                        <p :class="['__titleCosto']">
                          {{ costoMedioTotal
                          }}<!-- ,<strong class="_curren"
                            >00</strong
                          > -->
                          <!-- USD -->
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>

              <div v-if="statusOtros" :class="['__divTotales']">
                <p :class="['__titleServicio']">TOTAL ALTO DOLARES</p>
                <div :class="['__divContentServicio']">
                  <div>
                    <v-row>
                      <v-col
                        @click="_verCalculo(3)"
                        cols="12"
                        :class="['__divTotalEscenario']"
                      >
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col :class="['__mobilStatus']" cols="2">
                            <img width="100px" :src="logoPic" alt="" />
                          </v-col>
                          <v-col cols="9" md="8">
                            <p>SERVICIO LOGISTICOS PIC CARGO</p>
                            <p :class="['__calculosImpuestos']">
                              El costo logistico se mantiene en todos los
                              escenario
                            </p>
                            <span>+</span>
                          </v-col>
                          <v-col>
                            <p>
                              {{ costoLogistico
                              }}<!-- ,<strong class="_curren"
                                >00</strong
                              >
                              USD -->
                            </p>
                          </v-col>
                        </v-row>
                        <v-row :class="['__TitleTotalesEsc']">
                          <v-col :class="['__mobilStatus']" cols="2">
                            <img width="100px" :src="logo" alt="" />
                          </v-col>
                          <v-col cols="9" md="8">
                            <p>IMPUESTOS DE ADUANA ALTO</p>
                          </v-col>
                          <v-col>
                            <p>
                              {{ costoAlto
                              }}<!-- ,<strong class="_curren"
                                >00</strong
                              >
                              USD -->
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="7" md="8">
                        <p @click="_verCalculo(3)" :class="['__verCalculos']">
                          Ver calculos del impuesto
                        </p>
                      </v-col>
                      <v-col cols="5" md="4" :class="['__divTotalServicio']">
                        <p :class="['__titleCosto']">
                          {{ costoAltoTotal
                          }}<!-- ,<strong class="_curren"
                            >00</strong
                          >
                          USD -->
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn block rounded color="success">NUEVA COTIZACIÓN</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-bottom-sheet v-model="sheet">
        <v-sheet :class="['text-center', '__divSheet']">
          <v-btn class="mt-6" text color="red" @click="sheet = !sheet">
            Cerrar
          </v-btn>
          <div class="py-3">
            <v-simple-table :class="['__tableData']" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Concepto</th>
                    <th
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      Escenario Minimo
                    </th>
                    <th
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      Escenario Medio
                    </th>
                    <th
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      Escenario Máximo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <b :class="['__titleCostoCon']">IGV</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">16%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">16%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">16%</b>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b :class="['__titleCostoCon']">IPM</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">2%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">2%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">2%</b>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b :class="['__titleCostoCon']">PERCEPCION</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">3.50%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">3.50%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe']">3.50%</b>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b :class="['__titleCostoCon', '__valorem']">ADVALOREM</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe', '__valorem']">0%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe', '__valorem']">6%</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      <b :class="['__titleCostoConDe', '__valorem']">11%</b>
                    </td>
                  </tr>
                  <tr :class="['__divTotales']">
                    <td class="text-center">
                      <b :class="['__titleCostoCon']">Totales</b>
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 1 }]"
                      class="text-center"
                    >
                      <b
                        :class="[
                          '__titleCostoAduana',
                          { __activeCelTotal: activeCel == 1 },
                        ]"
                        >{{ costoMinimo }},<strong class="_curren">00</strong>
                        USD</b
                      >
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 2 }]"
                      class="text-center"
                    >
                      <b
                        :class="[
                          '__titleCostoAduana',
                          { __activeCelTotal: activeCel == 2 },
                        ]"
                        >{{ costoMedio }},<strong class="_curren">00</strong>
                        USD</b
                      >
                    </td>
                    <td
                      :class="[{ __activeCel: activeCel == 3 }]"
                      class="text-center"
                    >
                      <b
                        :class="[
                          '__titleCostoAduana',
                          { __activeCelTotal: activeCel == 3 },
                        ]"
                        >{{ costoAlto }},<strong class="_curren">00</strong>
                        USD</b
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="hee hee"
          :pdf-quality="3"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section class="pdf-content" style="padding: 50px" slot="pdf-content">
            <h1 style="text-align: center; padding-bottom: 20px">
              PRESUPUESTO FORMAL
            </h1>
            <p
              style="
                text-align: center;
                background: #a43542;
                color: white;
                border-radius: 20px;
                padding-bottom: 10px;
              "
            >
              DETALLES DEL SERVICIO
            </p>
            <p style="text-align: center; padding-bottom: 10px">
              Tarifa válida Desde el 14 de julio - Hasta el 25 de julio
            </p>
            <div
              style="
                text-align: center;
                border-top: solid 1px grey;
                border-bottom: solid 1px grey;
                padding: 10px 0px;
              "
            >
              <p>TRASPORTE MARITIMO - CHINA - SHANGHAI => PERÚ - CALLAO</p>
            </div>
            <div>
              <v-row style="font-size: 11px; padding-top: 10px">
                <v-col>
                  <ul style="font-size: line-height: 14px">
                    <li>Tipo</li>
                    <li>Cantidad</li>
                    <li>Gastos portuarios</li>
                    <li>Valor de mercancía</li>
                  </ul>
                </v-col>
                <v-col>
                  <ul>
                    <li>Trasporte a domicilio</li>
                    <li>Seguro de Mercancía</li>
                    <li>Impuestos de Aduana</li>
                    <li>Permisos de Importación</li>
                  </ul>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row>
                <v-col cols="8">
                  <p
                    style="
                      text-align: center;
                      background: #a43542;
                      color: white;
                      border-radius: 20px;
                    "
                  >
                    SERVICIO LOGISTICOS
                  </p>
                </v-col>
                <v-col>
                  <p
                    style="
                      text-align: center;
                      background: #e4e4e4;
                      color: black;
                      font-weight: 500;
                      border-radius: 20px;
                    "
                  >
                    MONTO
                  </p>
                </v-col>
                <v-col style="font-size: 12px" cols="8">
                  <ul>
                    <li>FLETE MARITIMO</li>
                    <li>THCD (Manejo de naviera en Destino)</li>
                    <li>ALMACEN ADUANERO</li>
                    <li>HONORARIOS DE AGENCIA DE ADUANA</li>
                    <li>TRANSPORTE A FABRICA IMPORTADOR</li>
                    <li>SEGURO DE MERCANCIA</li>
                    <li>VISTOS BUENOS</li>
                    <li>
                      GATE IN (Recepción de contenedor vacio en el puerto)
                    </li>
                  </ul>
                </v-col>
                <v-col cols="4" style="float: right">
                  <b style="font-size: 24px; font-weight: 800">13.556 USD</b>
                  <p style="font-size: 12px">+ IGV 18% 391 USD</p>
                </v-col>
                <v-col
                  style="text-align: center; background: #e4e4e4"
                  cols="12"
                >
                  <v-row>
                    <v-col cols="6">
                      <h3>IMPUESTOS DE ADUANA</h3>
                    </v-col>
                    <v-col cols="2">
                      <h4>Impuestos Bajos</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>Impuestos Medio</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>Impuestos Altos</h4>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -20px">
                    <v-col cols="6">
                      <p style="font-size: 14px">
                        Valor VARIABLE según descripción del producto
                      </p>
                    </v-col>
                    <v-col cols="2">
                      <h4>4.699 USD</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>5.926 USD</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>6.949 USD</h4>
                    </v-col>
                    <v-col
                      style="
                        background: #a43542;
                        color: white;
                        margin-top: -10px;
                      "
                      cols="6"
                    >
                      <h3>TOTALES (incluye IGV)</h3>
                    </v-col>
                    <v-col
                      style="
                        background: #a43542;
                        color: white;
                        margin-top: -10px;
                      "
                      cols="2"
                    >
                      <h4>10.349 USD</h4>
                    </v-col>
                    <v-col
                      style="
                        background: #a43542;
                        color: white;
                        margin-top: -10px;
                      "
                      cols="2"
                    >
                      <h4>11.146 USD</h4>
                    </v-col>
                    <v-col
                      style="
                        background: #a43542;
                        color: white;
                        margin-top: -10px;
                      "
                      cols="2"
                    >
                      <h4>11.810 USD</h4>
                    </v-col>
                  </v-row>
                </v-col>
                <div style="padding: 10px 90px; margin-bottom: 30px">
                  <div
                    style="
                      background: #eaeaea;
                      padding: 10px;
                      width: 100%;
                      border-radius: 5px;
                    "
                  >
                    <p
                      class="text-center"
                      style="
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 20px;
                      "
                    >
                      <img
                        style="width: 16px"
                        src="../../public/assets/img/boton-de-informacion.svg"
                        alt=""
                      />
                      IMPORTANTE
                    </p>
                    <ul style="font-size: 12px">
                      <li>Tarifas no aplican para mercancía peligrosa.</li>
                      <li>Salidas Semanales.</li>
                      <li>
                        Costos adicionales sugeridos por inspecciones aleatorias
                        de aduana, con controladas por PIC Cargo S.A.C.
                      </li>
                      <li>Tarifas no aplican para mercancía con sobrepeso</li>
                      <li>
                        Los documentos del embarque son responsabilidad del
                        proveedor y consignatario.
                      </li>
                      <li>
                        El embalaje de la carga debe ser acto para transporte.
                      </li>
                      <li style="color: red">
                        Tarifa basada en peso y volumen suministrado.
                      </li>
                      <li style="color: red">
                        Si el canal asignado por aduana es ROJO el costo
                        aumentará USD 150 - 200.
                      </li>
                      <li style="color: red">
                        Todos los servicios que se realicen dentro del
                        territorio peruano generarán IGV de 18%.
                      </li>
                      <li style="color: red">
                        Para su información el FLETE no genera IGV
                      </li>
                    </ul>
                  </div>
                </div>
                <v-row>
                  <v-col
                    style="font-weight: bold; justify-align: center"
                    cols="2"
                  >
                    <img
                      style="width: 50%"
                      src="../../public/assets/img/peru.svg"
                      alt=""
                    />
                    <p style="padding-top: 18px; padding-left: 65px">PERÚ</p>
                  </v-col>
                  <v-col style="font-size: 13px" cols="5">
                    <p>
                      Av. José Pardo, cuadra 1 y Calle Martir Olaya, Edif.
                      Empresarial Pardo 129, Ofic. 506, Miraflores, Lima, Perú
                    </p>
                    <p style="margin-top: 5px">
                      PIC Cargo S.A.C RUC: 20602647880
                    </p>

                    <img
                      style="width: 20px; float: left; margin-top: 5px"
                      src="../../public/assets/img/telephone.svg"
                      alt=""
                    />
                    <p style="padding-left: 25px; margin-top: 5px">
                      +511 326-9130 | +51 972 530303
                    </p>
                  </v-col>
                  <v-col cols="5">
                    <p
                      style="color: #3c4f6c; font-size: 24px; font-weight: bold"
                    >
                      ¡Tu Experto en Logística!
                    </p>
                    <img
                      style="width: 20px; float: left"
                      src="../../public/assets/img/globe-grid.svg"
                      alt=""
                    />
                    <i style="float: left; font-size: 13px; color: #a43542">
                      www.pic-cargo.com
                    </i>
                    <br />
                    <img
                      style="
                        width: 20px;
                        float: left;
                        margin-right: 2px;
                        margin-top: 5px;
                        margin-left: -20px;
                      "
                      src="../../public/assets/img/linkedin.svg"
                      alt=""
                    />
                    <img
                      style="
                        width: 20px;
                        float: left;
                        margin-right: 2px;
                        margin-top: 5px;
                      "
                      src="../../public/assets/img/facebook.svg"
                      alt=""
                    />
                    <img
                      style="
                        width: 20px;
                        float: left;
                        margin-right: 2px;
                        margin-top: 5px;
                      "
                      src="../../public/assets/img/instagram.svg"
                      alt=""
                    />
                    <img
                      style="
                        width: 20px;
                        float: left;
                        margin-right: 2px;
                        margin-top: 5px;
                      "
                      src="../../public/assets/img/twitter.svg"
                      alt=""
                    />
                    <img
                      style="
                        width: 20px;
                        float: left;
                        margin-right: 2px;
                        margin-top: 5px;
                      "
                      src="../../public/assets/img/tiktok.svg"
                      alt=""
                    />
                    <i
                      style="
                        float: left;
                        color: #a43542;
                        font-size: 13px;
                        margin-top: 5px;
                      "
                    >
                      @pic_cargo
                    </i>
                  </v-col>
                </v-row>
              </v-row>
            </div>
          </section>
        </vue-html2pdf>
      </div>
      <!-- <v-btn block color="success" :class="['mt-5']" rounded>Continuar</v-btn> -->
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";
import logoSunat from "../../public/assets/img/logoSunat.png";
import logoPic from "../../public/assets/img/logoPic.png";
export default {
  name: "TipoContenedor",
  data: () => {
    return {
      token: "",

      logo: logoSunat,
      logoPic: logoPic,

      service1: "FALSE",
      service2: "FALSE",
      service3: "FALSE",
      service4: "FALSE",
      service5: "FALSE",

      costoLogistico: "",
      costoMinimo: "",
      costoMinimoTotal: "",
      costoMedioTotal: "",
      costoAltoTotal: "",
      costoMedio: "",
      costoAlto: "",

      desTransporte: "",
      desTipo: "",
      desCantidad: "",
      desGastos: "",
      desProducto: "",
      desImpuestos: "",
      desValor: "",
      desImpuestoAduana: "",
      desTrasporteDomicilio: "",
      desSeguro: "",
      desPermiso: "",
      statusOtros: false,

      fecha_actual: new Date().toLocaleDateString(),

      sheet: false,
      numberSheet: "",
      activeCel: 0,
      statusDeta: false,

      destallesAduana: "Ver más detalles de su calculo",
      statusDetalles: false,

      detallesGenerales: "Ver más detalles",
    };
  },
  components: {
    VueHtml2pdf,
  },
  mounted() {
    this._getToken();
    //this._getFleteResumen();
    this._validaServicios();
    this._getDescription();
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    _verMasDet() {
      this.statusDeta = !this.statusDeta;
      if (this.statusDeta) {
        this.detallesGenerales = "Ver menos detalles";
      } else {
        this.detallesGenerales = "Ver más detalles";
      }
    },
    _getDescription() {
      if (localStorage.getItem("TipodeContenedor") == "FCL") {
        this.desTransporte = "Marítimo";
        this.desTipo = "Contenedor completo";
      } else if (localStorage.getItem("TipodeContenedor") == "LCL") {
        this.desTransporte = "Marítimo";
      } else {
        this.desTransporte = "Aéreo";
      }

      if (localStorage.getItem("Servicio1") == "true") {
        this.desGastos = "Si";
      } else {
        this.desGastos = "No";
      }

      this.desProducto = localStorage.getItem("selectedPermiso");

      if (localStorage.getItem("selectedPrevio") == "1") {
        this.desImpuestos = "Segunda importación";
      } else {
        this.desImpuestos = "Primera importación";
      }

      this.desValor = localStorage.getItem("ValorMercancia") + " USD";

      if (localStorage.getItem("Servicio2") == "true") {
        this.desImpuestoAduana = "Si";
      } else {
        this.desImpuestoAduana = "No";
      }

      this.desTrasporteDomicilio =
        localStorage.getItem("selectedProvinciaText") +
        " - " +
        localStorage.getItem("selectedDistritoText");

      if (localStorage.getItem("Servicio4") == "true") {
        this.desSeguro = "Si";
      } else {
        this.desSeguro = "No";
      }

      this.desPermiso = localStorage.getItem("Regulador");
    },
    _verCalculo(number) {
      this.sheet = !this.sheet;
      this.activeCel = number;
    },
    _validaServicios() {
      if (localStorage.getItem("Servicio1") == "true") {
        this.service1 = "TRUE";
      } else {
        this.service1 = "FALSE";
      }
      if (localStorage.getItem("Servicio2") == "true") {
        this.service2 = "TRUE";
      } else {
        this.service2 = "FALSE";
      }

      if (localStorage.getItem("Servicio3") == "true") {
        this.service3 = "TRUE";
      } else {
        this.service3 = "FALSE";
      }
      if (localStorage.getItem("Servicio4") == "true") {
        this.service4 = "TRUE";
      } else {
        this.service4 = "FALSE";
      }
      if (localStorage.getItem("Servicio5") == "true") {
        this.service5 = "TRUE";
      } else {
        this.service5 = "FALSE";
      }
    },
    _getFleteResumen() {
      let self = this;
      let dataPost = {
        token: localStorage.getItem("token"),
      };
      var utlConsulta = "";
      if (localStorage.getItem("TipodeContenedor") == "FCL") {
        utlConsulta = process.env.VUE_APP_API_URL + "/get_ctz_fcl_resum_subto";
      } else if (localStorage.getItem("TipodeContenedor") == "LCL") {
        utlConsulta = process.env.VUE_APP_API_URL + "/get_ctz_lcl_resum_subto";
      } else if (localStorage.getItem("TipodeContenedor") == "AEREO") {
        utlConsulta =
          process.env.VUE_APP_API_URL_AE + "/get_ctz_aereo_resum_subto";
      }
      var config = {
        method: "post",
        url: utlConsulta,
        headers: {
          "Content-Type": "application/json",
        },
        data: dataPost,
      };
      axios(config)
        .then(function (response) {
          let dataGen = JSON.parse(JSON.stringify(response.data));
          let dataArra = dataGen.data.r_dat;
          dataArra.map(function (item) {
            if (item.tipo == "SUBTOTAL") {
              self.costoLogistico = parseFloat(
                Math.round(item.valor, 2)
              ).toLocaleString("de-DE");
              localStorage.setItem("SUBTOTAL", item.valor);
            }
            if (item.tipo == "IMPUESTOS DE ADUANA ALTOS") {
              self.costoAlto = parseFloat(item.valor).toLocaleString("de-DE", {
                maximumSignificantDigits: 4,
              });
              localStorage.setItem("IMPUESTOSALTOS", item.valor);
            }
            if (item.tipo == "IMPUESTOS DE ADUANA BAJOS") {
              self.costoMinimo = parseFloat(item.valor).toLocaleString(
                "de-DE",
                { maximumSignificantDigits: 4 }
              );
              localStorage.setItem("IMPUESTOSBAJOS", item.valor);
            }
            if (item.tipo == "IMPUESTOS DE ADUANA MEDIOS") {
              self.costoMedio = parseFloat(item.valor).toLocaleString("de-DE", {
                maximumSignificantDigits: 4,
              });
              localStorage.setItem("IMPUESTOSMEDIOS", item.valor);
            }

            self.costoMinimoTotal = (
              parseFloat(self.costoMinimo) + parseFloat(self.costoLogistico)
            ).toFixed(3);

            self.costoMedioTotal = (
              parseFloat(self.costoMedio) + parseFloat(self.costoLogistico)
            ).toFixed(3);

            self.costoAltoTotal = (
              parseFloat(self.costoAlto) + parseFloat(self.costoLogistico)
            ).toFixed(3);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    _validaDetalles() {
      this.statusDetalles = !this.statusDetalles;
      if (this.statusDetalles) {
        this.destallesAduana = "Ver menos detalles de su calculo";
      } else {
        this.destallesAduana = "Ver más detalles de su calculo";
      }
    },
    _verOtros() {
      this.statusOtros = !this.statusOtros;
    },
    _resetData() {
      localStorage.setItem();
    },
    _getToken() {
      let self = this;
      if (localStorage.getItem("TipodeContenedor") == "FCL") {
        var c1;
        var c2;
        var c3;
        var c4;

        if (localStorage.getItem("selectedPrevio")) {
          if (localStorage.getItem("selectedPrevio") == 1) {
            previo = "true";
          } else {
            previo = "false";
          }
        } else {
          previo = "false";
        }

        if (localStorage.getItem("Contenedor20Std")) {
          c1 = parseInt(localStorage.getItem("Contenedor20Std"));
        } else {
          c1 = 0;
        }
        if (localStorage.getItem("Contenedor40Std")) {
          c2 = parseInt(localStorage.getItem("Contenedor40Std"));
        } else {
          c2 = 0;
        }
        if (localStorage.getItem("Contenedor40HC")) {
          c3 = parseInt(localStorage.getItem("Contenedor40HC"));
        } else {
          c3 = 0;
        }
        if (localStorage.getItem("Contenedor40NOR")) {
          c4 = parseInt(localStorage.getItem("Contenedor40NOR"));
        } else {
          c4 = 0;
        }

        var dataPost = {
          datos: [
            parseInt(localStorage.getItem("PaisOrigenId")),
            parseInt(localStorage.getItem("PaisEndId")),
            "{{1," + c1 + "},{2," + c2 + "},{3," + c3 + "},{4," + c4 + "}}",
            parseFloat(localStorage.getItem("ValorMercancia")),
            localStorage.getItem("selectedProvincia"),
            previo,
            localStorage.getItem("Servicio1"),
            localStorage.getItem("Servicio2"),
            localStorage.getItem("Servicio3"),
            localStorage.getItem("Servicio4"),
          ],
        };

        console.log(dataPost);

        var config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/get_ctz_fcl_token",
          headers: {
            "Content-Type": "application/json",
          },
          data: dataPost,
        };
        axios(config)
          .then(function (response) {
            let dataGen = JSON.parse(JSON.stringify(response.data));

            self.token = dataGen.data.r_dat[0].token;
            localStorage.setItem("token", self.token);

            self._getFleteResumen();
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (localStorage.getItem("TipodeContenedor") == "LCL") {
        var previo;
        if (localStorage.getItem("selectedPrevio")) {
          if (localStorage.getItem("selectedPrevio") == 1) {
            previo = "true";
          } else {
            previo = "false";
          }
        } else {
          previo = "false";
        }

        dataPost = {
          datos: [
            parseInt(localStorage.getItem("PaisOrigenId")),
            parseInt(localStorage.getItem("PaisEndId")),
            parseFloat(localStorage.getItem("peso")),
            parseInt(localStorage.getItem("selectedPeso")),
            parseFloat(localStorage.getItem("volumen")),
            parseInt(localStorage.getItem("selectedVolumenUnit")),
            parseFloat(localStorage.getItem("bultos")),
            parseFloat(localStorage.getItem("ValorMercancia")),
            localStorage.getItem("selectedProvincia"),
            previo,
            localStorage.getItem("Servicio1"),
            localStorage.getItem("Servicio2"),
            localStorage.getItem("Servicio3"),
            localStorage.getItem("Servicio4"),
          ],
        };

        console.log(dataPost);

        config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/get_ctz_lcl_token",
          headers: {
            "Content-Type": "application/json",
          },
          data: dataPost,
        };
        axios(config)
          .then(function (response) {
            let dataGen = JSON.parse(JSON.stringify(response.data));

            self.token = dataGen.data.r_dat[0].token;
            localStorage.setItem("token", self.token);

            self._getFleteResumen();
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (localStorage.getItem("TipodeContenedor") == "AEREO") {
        previo;
        if (localStorage.getItem("selectedPrevio")) {
          if (localStorage.getItem("selectedPrevio") == 1) {
            previo = "true";
          } else {
            previo = "false";
          }
        } else {
          previo = "false";
        }

        dataPost = {
          datos: [
            parseInt(localStorage.getItem("PaisOrigenId")),
            parseInt(localStorage.getItem("PaisEndId")),
            parseFloat(localStorage.getItem("peso")),
            parseInt(localStorage.getItem("selectedPeso")),
            parseFloat(localStorage.getItem("volumen")),
            parseInt(localStorage.getItem("selectedVolumenUnit")),
            parseFloat(localStorage.getItem("bultos")),
            parseFloat(localStorage.getItem("ValorMercancia")),
            localStorage.getItem("selectedProvincia"),
            previo,
            localStorage.getItem("Servicio1"),
            localStorage.getItem("Servicio2"),
            localStorage.getItem("Servicio3"),
            localStorage.getItem("Servicio4"),
          ],
        };

        console.log(dataPost);

        config = {
          method: "post",
          url: process.env.VUE_APP_API_URL_AE + "/get_ctz_aereo_token",
          headers: {
            "Content-Type": "application/json",
          },
          data: dataPost,
        };
        axios(config)
          .then(function (response) {
            let dataGen = JSON.parse(JSON.stringify(response.data));

            self.token = dataGen.data.r_dat[0].token;
            localStorage.setItem("token", self.token);

            self._getFleteResumen();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .__mobilStatus {
    display: none;
  }
}

.pdf-content {
  background: white;
}

.__titleMain {
  font-size: 1.2vw;
  padding: 30px 10px 10px 10px;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 4.2vw;
  }
}

._curren {
  font-size: 20px !important;
  content: "00" !important;
  vertical-align: top !important;
}

.__verMasDetalles {
  margin-left: 20px;
  font-size: 18px;
  color: blue;
  text-decoration: underline;

  @media (max-width: 800px) {
    font-size: 14px;
  }
}

.__itemsDescription {
  font-size: 12px;

  span {
    float: right;
  }
}

.__verCalculosImp {
  margin-top: -30px;
  cursor: pointer;
  text-decoration: underline;
}

.__activeCel {
  background: #b4ffd6;
}

.__activeCelTotal {
  font-size: 40px !important;

  @media (max-width: 800px) {
    font-size: 20px !important;
  }
}

.__calculosImpuestos {
  font-size: 16px;
  margin-top: -10px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
}

.__divTotalEscenario {
  line-height: 16px;
  .__TitleEscenario {
    font-size: 26px;
    color: #465c6b;
    font-weight: 500;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
  .__SubTitleEscenario {
    font-size: 14px;
  }
}

.__otrosEscenarios {
  font-size: 18px;
}

.__detalllesAduana {
  text-align: right;
  margin-top: 5px;
  font-size: 16px;
  cursor: pointer;
}

.__titleCostoAduana {
  font-weight: 800;
  font-size: 17px;
  color: #1d1d1d;

  @media (max-width: 800px) {
    font-size: 12px;
  }
}

.__valorem {
  color: red !important;
  font-weight: bold !important;
}

.__divTotales {
  background: #d0e4f7;
}

.__titleCostoCon {
  font-weight: 400;
  font-size: 17px;
  color: #797979;
}

.__divContainerRuta {
  padding: 10px 10px;
}

.__divMainTipo {
  padding: 10px;
}

.__activeServices {
  background: #24587b !important;
  color: white;
  font-weight: 400;
}

.__divServicio {
  .__divContentServicio {
    padding: 10px;
  }

  .__divItemServicio {
    line-height: 3px;
  }

  .__divTotalServicio {
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 600;

      text-align: center;
    }
    .__titleCosto {
      font-size: 36px;
      color: #3770b7;
    }
  }
  .__titleServicio {
    background: #668ba3;
    color: white;
    padding: 10px;
    font-size: 18px;
  }
  background: #eeeeee;
}

.__divTotales {
  background: #eeeeee;

  .__divContentServicio {
    padding: 10px;
  }

  .__divItemServicio {
    line-height: 3px;
  }

  .__divTotalServicio {
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .__titleCosto {
      font-size: 45px;
      color: rgb(145, 27, 27);

      @media (max-width: 800px) {
        font-size: 24px;
      }
    }
  }
  .__titleServicio {
    background: #668ba3;
    color: white;
    padding: 10px;
    font-size: 18px;
  }
}

.__TitleTotalesEsc {
  color: #292929;
  font-size: 20px;
  margin-top: -10px;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  p {
    padding-top: 3px;
  }
  span {
    font-size: 42px;
    font-weight: 500;
    margin-top: -8px;
    position: absolute;
    padding-left: 100px;

    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
}

.__verCalculos {
  font-weight: 500;
  color: rgb(13, 13, 187);
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: 800px) {
    font-size: 12px;
  }
}

.__botonOtro {
  font-weight: 500;
  color: rgb(13, 13, 187);
  cursor: pointer;
  text-decoration: underline;
}

.__divServices {
  background: #e0e7ec;
  margin-bottom: 5px;
  border-radius: 15px;

  img {
    max-width: 20%;

    float: right;

    @media (max-width: 800px) {
      max-width: 40%;
    }
  }
}
</style>
