import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Index.vue";
import Maritima from "../views/Maritimo/TipoServicio.vue";
import Contenedor from "../views/Maritimo/Contenedor.vue";
import Aereo from "../views/Aereo/index.vue";
import Ruta from "../views/Ruta/Index.vue";
import Servicios from "../views/Servicios.vue";
import Presupuesto from "../views/Presupuesto.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Maritima",
    name: "ImportacionMaritima",
    component: Maritima,
  },
  {
    path: "/Contenedor",
    name: "TipoContenedor",
    component: Contenedor,
  },
  {
    path: "/Aereo",
    name: "Aereo",
    component: Aereo,
  },
  {
    path: "/Ruta",
    name: "Ruta",
    component: Ruta,
  },
  {
    path: "/Servicios",
    name: "Servicios",
    component: Servicios,
  },
  {
    path: "/Presupuesto",
    name: "Presupuesto",
    component: Presupuesto,
  },
  
];

const router = new VueRouter({
  routes,
});

export default router;
